import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTrigger,
} from '@shadcn/components/ui/drawer';
import { useCart } from '../../_providers/Cart';
import { Button } from '../Button';
import { useTranslations } from 'next-intl';
import ChevronLeft from '../../_icons/Chevron-left';
import { CartItem } from '../CartItem';
import { Courseversion } from '@/payload/payload-types';
import { Link, useRouter } from '@/navigation';
import { CartShoppingBag } from '../CartShoppingBag';
import { ScrollArea } from '@shadcn/components/ui/scroll-area';
import { CartTotal } from '../CartSummary';
import { DialogTitle } from '@shadcn/components/ui/dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTrackEvent } from '../../_providers/TrackEventProvider';

export const CartDrawer: React.FC<{
	className?: string;
}> = props => {
	const { cart } = useCart();
	const t = useTranslations('Cart');
	const router = useRouter();
	const trackEvent = useTrackEvent();

	return (
		<Drawer direction="right" disablePreventScroll>
			<DrawerTrigger
				onClick={() =>
					trackEvent('cart_drawer_opened', { component: 'cart_button_desktop' })
				}
			>
				<CartShoppingBag />
			</DrawerTrigger>

			<DrawerContent className="px-12 py-11">
				<VisuallyHidden>
					<DialogTitle>{t('cart')}</DialogTitle>
				</VisuallyHidden>
				<VisuallyHidden>
					<DrawerDescription>{t('cart-description')}</DrawerDescription>
				</VisuallyHidden>
				<DrawerHeader className="mb-16 flex items-center justify-between">
					<DrawerClose asChild className="">
						<Button variant="ghost">
							<span>
								<ChevronLeft />
							</span>
							{t('close')}
						</Button>
					</DrawerClose>
					<DrawerClose asChild>
						<CartShoppingBag />
					</DrawerClose>
				</DrawerHeader>
				<ScrollArea className="mb-4">
					<div className="space-y-4">
						{!cart?.cartItems?.length && <p>{t('cartEmpty')}</p>}
						{typeof cart?.cartItems?.length === 'number' &&
							cart?.cartItems?.length > 0 &&
							cart.cartItems.map(cartItem => {
								if (
									!cartItem.orderedCollection ||
									typeof cartItem.orderedCollection.value === 'string'
								) {
									return null;
								}
								const item = cartItem.orderedCollection.value as Courseversion;
								return (
									<CartItem
										key={`cartDrawerComponent-${item.id}`}
										disableCounter={false}
										type="mobile"
										item={item}
										cartItem={cartItem}
									></CartItem>
								);
							})}
					</div>
				</ScrollArea>
				<DrawerFooter className="bg-gold-light-8 space-y-8 rounded-3xl p-8">
					<CartTotal />
					<DrawerClose asChild className="w-full">
						<Button
							className="w-full"
							intent="secondary"
							onClick={() => {
								router.push('/cart');
								trackEvent('go_to_cart_clicked', { component: 'drawer' });
							}}
						>
							{t('checkout')}
						</Button>
					</DrawerClose>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

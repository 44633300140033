import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@shadcn/lib/utils';

const chipVariants = cva('flex items-center justify-center text-white font-bold', {
	variants: {
		size: {
			xs: 'text-[10px] leading-[20px] px-[5px] h-[20px] rounded-2xl uppercase',
			small: 'text-sm leading-4 py-1 px-[10px] h-[24px] rounded-2xl',
			large: 'text-base leading-8 py-1 px-4 h-[32px] rounded-3xl',
		},
		bgColor: {
			default: 'bg-muted-blue',
			green: 'bg-success',
			navy: 'bg-brand-navy',
		},
	},
	defaultVariants: {
		size: 'small',
		bgColor: 'default',
	},
});

type ChipType = {
	size?: 'xs' | 'small' | 'large';
	bgColor?: 'default' | 'green' | 'navy';
	children: React.ReactNode;
	className?: string;
};

export interface ChipProps
	extends React.HTMLAttributes<HTMLParagraphElement>,
		VariantProps<typeof chipVariants> {
	children: React.ReactNode;
}

export const Chip: React.FC<ChipType> = ({ children, size, bgColor, className, ...props }) => {
	return (
		<p className={cn(chipVariants({ size, bgColor, className }))} {...props}>
			{children}
		</p>
	);
};

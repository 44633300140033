'use client';

import React, { Suspense, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import type { Header as HeaderType } from '@/payload/payload-types';
import { cn } from '@shadcn/lib/utils';
import logo from '../../../../../../public/assets/ebs-logo.svg';
import { Link, useRouter } from '@/navigation';
import { useAuth } from '../../../_providers/Auth';
import { CartLink } from '../../CartLink';
import { CMSLink } from '../../Link';
import LocaleSwitcher, { LocaleTrigger } from '../../LocaleSwitcher';
import { AuthModal } from '../../AuthModal';
import {
	Popover,
	PopoverClose,
	PopoverContent,
	PopoverTrigger,
} from '@shadcn/components/ui/popover';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Burger from '../../../_icons/Burger';
import { CartShoppingBag } from '../../CartShoppingBag';
import Profile from '../../../_icons/Profile';
import { useMobileOverlayContext } from '../../../_providers/MobileOverlay';
import { MobileOverlay } from '../MobileOverlay';
import { useTrackEvent } from '../../../_providers/TrackEventProvider';
import { SlugTranslationsType } from '@/shared/typescript';

const MobileHeaderNav: React.FC<{
	navItems: HeaderType['navItems'];
	slugTranslations: SlugTranslationsType;
	className?: string;
}> = ({ navItems = [], slugTranslations, className }) => {
	const { setMobileOverlayVisibility } = useMobileOverlayContext();
	const [mobileMenuType, setMobileMenuType] = useState<
		'cart' | 'burger' | 'account' | 'language' | null
	>(null);
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const trackEvent = useTrackEvent();

	useEffect(() => {
		setHasMounted(true);
	}, []);

	type MobileOverlaySettings = {
		visibility: boolean;
		menuType?: 'cart' | 'burger' | 'account' | 'language' | null;
	};

	const onMobileNavigationClick = ({ visibility, menuType = null }: MobileOverlaySettings) => {
		setMobileMenuType(null);
		setMobileOverlayVisibility(visibility);
		if (visibility && menuType) setMobileMenuType(menuType);
	};

	type MobileElement = {
		menuType: 'cart' | 'burger' | 'account' | 'language';
		component: React.ReactNode;
	};

	const mobileNavElements: MobileElement[] = [
		{
			menuType: 'account',
			component: <Profile />,
		},
		{
			menuType: 'language',
			component: <LocaleTrigger type="mobile" />,
		},
		{
			menuType: 'cart',
			component: (
				<CartShoppingBag
					onClick={() =>
						trackEvent('cart_drawer_opened', { component: 'cart_button_mobile' })
					}
					type="mobile"
				/>
			),
		},
		{
			menuType: 'burger',
			component: <Burger />,
		},
	];

	return (
		<>
			<nav className={cn('flex w-full items-center justify-end space-x-1', className)}>
				{mobileNavElements.map(({ menuType, component }) => {
					return (
						<span
							key={menuType}
							className="cursor-pointer rounded-3xl p-2 hover:bg-black/10"
							onClick={() => onMobileNavigationClick({ visibility: true, menuType })}
						>
							{component}
						</span>
					);
				})}
			</nav>
			{hasMounted &&
				createPortal(
					<MobileOverlay
						slugTranslations={slugTranslations}
						navItems={navItems}
						type={mobileMenuType}
					/>,
					document.body,
				)}
		</>
	);
};

const DesktopHeaderNav: React.FC<{
	navItems: HeaderType['navItems'];
	slugTranslations: SlugTranslationsType;
	className?: string;
}> = ({ navItems = [], slugTranslations, className }) => {
	const t = useTranslations();
	const { user, logout } = useAuth();
	const router = useRouter();
	const linkClasses = 'p-2 mr-6 self-center';

	const performLogout = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		try {
			await logout();

			router.push('/');
		} catch (_) {}
	};

	return (
		<nav className={cn('w-full self-center antialiased', className)}>
			{navItems?.map(({ link }, i) => {
				return <CMSLink key={i} {...link} className={linkClasses} />;
			})}

			<div className="mr-0 ml-auto flex items-stretch justify-center space-x-4">
				{user && (
					<Popover>
						<PopoverTrigger className="fs-mask">
							{user.firstName} {user.lastName}
						</PopoverTrigger>
						<PopoverContent>
							<div className="flex flex-col space-y-4">
								<PopoverClose asChild={true}>
									<Link className="hover:text-gold p-2" href={'/account/orders'}>
										{t('myOrders')}
									</Link>
								</PopoverClose>
								{/* <PopoverClose asChild={true}>
									<Link className="p-2 hover:text-gold" href={'/account'}>
										{t('my-account')}
									</Link>
								</PopoverClose> */}

								<PopoverClose asChild={true}>
									<a
										className="hover:text-gold p-2"
										href=""
										onClick={performLogout}
									>
										{t('Logout')}
									</a>
								</PopoverClose>
							</div>
						</PopoverContent>
					</Popover>
				)}
				{!user && <AuthModal />}
				<Suspense>
					<LocaleSwitcher slugTranslations={slugTranslations} />
				</Suspense>
				<CartLink className={linkClasses} />
			</div>
		</nav>
	);
};

const HeaderNav: React.FC<{
	header: HeaderType;
	slugTranslations: SlugTranslationsType;
}> = ({ header, slugTranslations }) => {
	return (
		<header className="absolute">
			<div className="header-element h-mb-header-height lg:h-header-height fixed z-20 mx-auto flex w-screen items-center bg-transparent p-4 backdrop-blur-md lg:px-12 lg:py-8">
				<Link href="/courses" className="mr-12">
					<Image
						alt="EBS logo"
						src={logo}
						width={48}
						priority={true}
						className="h-8 lg:h-12"
					/>
				</Link>
				<MobileHeaderNav
					className="lg:hidden"
					navItems={header.navItems}
					slugTranslations={slugTranslations}
				/>
				<DesktopHeaderNav
					className="hidden lg:flex"
					navItems={header.navItems}
					slugTranslations={slugTranslations}
				/>
			</div>
		</header>
	);
};

export default HeaderNav;

'use client';

import React from 'react';

import { AuthProvider } from '../_providers/Auth';
import { CartProvider } from '../_providers/Cart';
import { MobileOverlayProvider } from '../_providers/MobileOverlay';
import { ThemeProvider } from './Theme';
import { User } from '@/payload/payload-types';
import { TrackEventProvider } from '../_providers/TrackEventProvider';
import { HeaderHeightProvider } from '../_providers/HeaderHeight';

export const Providers: React.FC<{
	children: React.ReactNode;
	user?: User;
}> = ({ children, user }) => {
	return (
		<ThemeProvider>
			<AuthProvider initialUser={user}>
				<CartProvider>
					<MobileOverlayProvider>
						<TrackEventProvider user_id={user?.id || 'no_user'}>
							<HeaderHeightProvider>{children}</HeaderHeightProvider>
						</TrackEventProvider>
					</MobileOverlayProvider>
				</CartProvider>
			</AuthProvider>
		</ThemeProvider>
	);
};

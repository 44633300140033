import React, { createContext, useContext, useState, useEffect } from 'react';

const HeaderHeightContext = createContext(0);

export const useHeaderHeight = () => useContext(HeaderHeightContext);

export const HeaderHeightProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [headerHeight, setHeaderHeight] = useState(0);

	const updateHeaderHeight = () => {
		const header = document.querySelector('.header-element') as HTMLElement;
		if (header) {
			setHeaderHeight(header.offsetHeight);
		}
	};

	useEffect(() => {
		updateHeaderHeight();

		window.addEventListener('resize', updateHeaderHeight);

		return () => {
			window.removeEventListener('resize', updateHeaderHeight);
		};
	}, []);

	return (
		<HeaderHeightContext.Provider value={headerHeight}>{children}</HeaderHeightContext.Provider>
	);
};

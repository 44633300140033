import { useLocale, useTranslations } from 'next-intl';
import { Course, Courseversion } from '@/payload/payload-types';
import { CounterInput } from '@shadcn/components/ui/input';
import dayjs from 'dayjs';
import { Link } from '@/navigation';

import React, { Fragment } from 'react';
import { useCart } from '../../_providers/Cart';
import { formatPrice } from '../../_utilities/format-price';
import { Chip } from '../Chip';
import { CartItem as ICartItem } from '../../_providers/Cart/reducer';
import { Button } from '../Button';
import Trash from '../../_icons/Trash';
import { useMobileOverlayContext } from '../../_providers/MobileOverlay';
import { Skeleton } from '@shadcn/components/ui/skeleton';

const PriceChip: React.FC<{
	discountedPrice?: number;
	normalPrice: number;
	locale: string;
	discountCodeApplied?: boolean | null;
}> = ({ discountedPrice, normalPrice, locale, discountCodeApplied }) => {
	return discountedPrice && !discountCodeApplied ? (
		<Fragment>
			{/* <Chip className="w-min line-through">{formatPrice(normalPrice, locale, 'EUR')}</Chip> */}
			<Chip className="w-min" bgColor="green">
				{formatPrice(discountedPrice, locale, 'EUR')}
			</Chip>
		</Fragment>
	) : (
		<Chip className="w-min">{formatPrice(normalPrice, locale, 'EUR')}</Chip>
	);
};

type CartItemType = {
	item: Courseversion;
	cartItem: ICartItem;
	disableCounter?: boolean;
	type?: 'mobile' | 'desktop';
};

export const CartItem: React.FC<CartItemType> = ({
	item,
	cartItem,
	disableCounter,
	type = 'desktop',
}) => {
	const locale = useLocale();
	const t = useTranslations('Cart');
	const [seatInputValue, setSeatInputValue] = React.useState<number>(cartItem.quantity as number);
	const { addItemToCart, deleteItemFromCart, isCartSyncInProgress } = useCart();
	const { setMobileOverlayVisibility } = useMobileOverlayContext();

	React.useEffect(() => {
		addItemToCart({
			...cartItem,
			quantity: seatInputValue,
		});
	}, [seatInputValue]);

	const normalPrice = cartItem.quantity ? cartItem.price! * cartItem.quantity : cartItem.price!;
	const discountedPrice = cartItem.quantity
		? cartItem.discountedPrice! * cartItem.quantity
		: cartItem.discountedPrice!;
	const course = item.course as Course;

	if (type === 'mobile') {
		return (
			<div className="flex flex-col items-start justify-between space-y-4 rounded-3xl border px-6 py-4">
				<div className="flex w-full justify-between">
					<div className="space-y-1">
						<Link
							onClick={() => setMobileOverlayVisibility(false)}
							className="text-muted-navy mr-2 flex-wrap font-bold hover:underline"
							href={{
								pathname: '/courses/[slug]',
								params: {
									slug: course.slug!,
								},
							}}
							key={course.id}
						>
							{course.title}
						</Link>

						<p className="text-gold-light text-sm font-medium italic">
							{dayjs(item.timeEdit.startDate).format('DD.MM.YYYY')} -{' '}
							{dayjs(item.timeEdit.endDate).format('DD.MM.YYYY')}
						</p>
					</div>
					<div className="w-fit">
						<PriceChip
							discountedPrice={discountedPrice}
							normalPrice={normalPrice}
							locale={locale}
							discountCodeApplied={cartItem.discountCodeApplied}
						/>
					</div>
				</div>
				<div className="flex w-full items-center space-x-4">
					{!disableCounter && (
						<>
							<p className="text-muted-navy">{t('seats')}</p>
							<CounterInput
								isLoading={isCartSyncInProgress}
								size="small"
								setValue={setSeatInputValue}
								type="number"
								value={seatInputValue}
								maxCount={item.remainingSlots || 10} // TODO: 10 - needs business decision. Mitu kursust korraga osta saab?
							/>
						</>
					)}

					<Button
						onClick={() => deleteItemFromCart(cartItem)}
						disabled={isCartSyncInProgress}
						variant="icon"
					>
						<Trash />
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			{/* // mobile view */}
			<div className="flex flex-col items-start justify-between space-y-4 rounded-3xl border px-6 py-4 lg:hidden">
				<div className="flex w-full justify-between">
					<div className="space-y-1">
						<Link
							onClick={() => setMobileOverlayVisibility(false)}
							className="text-muted-navy mr-2 flex-wrap font-bold hover:underline"
							href={{
								pathname: '/courses/[slug]',
								params: {
									slug: course.slug!,
								},
							}}
							key={course.id}
						>
							{course.title}
						</Link>

						<p className="text-gold-light text-sm font-medium italic">
							{dayjs(item.timeEdit.startDate).format('DD.MM.YYYY')} -{' '}
							{dayjs(item.timeEdit.endDate).format('DD.MM.YYYY')}
						</p>
					</div>
					<div className="w-fit">
						<PriceChip
							discountedPrice={discountedPrice}
							normalPrice={normalPrice}
							locale={locale}
							discountCodeApplied={cartItem.discountCodeApplied}
						/>
					</div>
				</div>
				<div className="flex w-full items-center space-x-4">
					{!disableCounter && (
						<>
							<p className="text-muted-navy">{t('seats')}</p>
							<CounterInput
								isLoading={isCartSyncInProgress}
								size="small"
								setValue={setSeatInputValue}
								type="number"
								value={seatInputValue}
								maxCount={item.remainingSlots || 10} // TODO: 10 - needs business decision. Mitu kursust korraga osta saab?
							/>
						</>
					)}

					<Button
						onClick={() => deleteItemFromCart(cartItem)}
						disabled={isCartSyncInProgress}
						variant="icon"
					>
						<Trash />
					</Button>
				</div>
			</div>

			{/* // desktop view */}
			<div
				key={item.id}
				className="hidden items-center justify-between space-x-4 rounded-3xl border px-6 py-4 lg:flex"
			>
				<div className="w-full">
					<Link
						className="text-muted-navy flex-wrap font-bold hover:underline"
						href={{
							pathname: '/courses/[slug]',
							params: {
								slug: course.slug!,
							},
						}}
						key={course.id}
					>
						{course.title}
					</Link>

					<p className="text-gold-light text-sm font-medium italic">
						{dayjs(item.timeEdit.startDate).format('DD.MM.YYYY')} -{' '}
						{dayjs(item.timeEdit.endDate).format('DD.MM.YYYY')}
					</p>
				</div>
				<div className="mt-2 flex w-full items-center justify-items-start space-x-4 lg:mt-0">
					{!disableCounter && (
						<div className="flex w-fit flex-1 items-center space-x-2">
							<p className="text-muted-navy">{t('seats')}</p>
							<CounterInput
								isLoading={isCartSyncInProgress}
								setValue={setSeatInputValue}
								type="number"
								value={seatInputValue}
								maxCount={item.remainingSlots || 10} // 10 - needs business decision
							/>
						</div>
					)}

					<div className="flex w-24 justify-end">
						<PriceChip
							discountedPrice={discountedPrice}
							normalPrice={normalPrice}
							locale={locale}
							discountCodeApplied={cartItem.discountCodeApplied}
						/>
					</div>

					<Button
						onClick={() => deleteItemFromCart(cartItem)}
						disabled={isCartSyncInProgress}
						variant="icon"
					>
						<Trash />
					</Button>
				</div>
			</div>
		</>
	);
};
